const config = {
    basename: '/',
    baseURL: 'https://api.diplomasivakfi.com',
    apiURL: 'https://api.diplomasivakfi.com',
    imageUrl: 'https://api.diplomasivakfi.com/images',
    turkeyinfoImage: 'https://api.diplomasivakfi.com/turkeyinfo_image',
    turkeyinfoPrefix: 'api/auth/turkeyinfo',
    diplomasivakfiPrefix: 'api/auth/diplomasivakfi',
    earnportalPrefix: 'api/auth/earnportal',
    defaultPath: '/',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12
};

export default config;
