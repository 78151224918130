import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MainLayout from 'layout/MainLayout';

const Contents = Loadable(lazy(() => import('views/TurkeyInfo/contents')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const TurkeyinfoRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/turkeyinfo/contents',
            element: <Contents />
        },
    ]
};

export default TurkeyinfoRoutes;
