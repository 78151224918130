import { Component } from 'react';
import axios from 'axios';
import config from 'config';
import { ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { IconLogout } from '@tabler/icons';

class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            logout: this.props.logout,
            user: localStorage.getItem('loggedInTime'),
            token: JSON.parse(localStorage.getItem('userData'))['token'],
            email: JSON.parse(localStorage.getItem('userData'))['email'],
        };
    }

    handleLogout = () => {
        this.revokeAccessToken();
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('userData');
        localStorage.removeItem('loggedInTime');
        // this.props.history.push('/login');
    };

    componentDidUpdate = () => {
        this.updateLocalDetails();
    };

    UNSAFE_componentWillMount = () => {
        this.updateLocalDetails();
    };

    updateLocalDetails = () => {
        let userData = localStorage.getItem('userData');
        userData = JSON.parse(userData);
        if (userData) {
            if (Object.keys(userData).length > 0) {
                let lastLoggedInTime = localStorage.getItem('loggedInTime');
                let now = (new Date()).getTime();
                let rememberMe = localStorage.getItem('isRemember');
                if ((rememberMe !== 'true' && (now - lastLoggedInTime) > (10000*60*24)) || this.props.logout) // if user doesn't perform any activity in 24 minutes than log him/her out.
                    this.handleLogout();

                if (axios.defaults.headers.common['Authorization'] == undefined) {
                    axios.defaults.headers.common['Accept'] = 'application/json';
                    axios.defaults.headers.common['Authorization'] = 'Bearer '+ userData.access_token;
                }
                localStorage.setItem('loggedInTime', (new Date()).getTime());

                if (this.state.name == '')
                    this.setState({name: userData.name || 'Anonymous'})
            } else {
                this.handleLogout();
            }
        }
    };

    revokeAccessToken = () => {
        axios.post(`${config.baseURL}/api/auth/logout`, {
            token: this.state.token,
            email: this.state.email
        })
        .then((response) => {
            // console.log(response);
        }).catch((err) => {
            console.log(err);
        });
    };

    render() {
        return (
            <>
                <a className="nav-link" href="#" 
                    onClick={this.handleLogout}
                    style={{
                        textDecoration: 'none',
                        color: 'inherit',
                        display: 'flex',
                }}>
                    <ListItemButton
                        sx={{ borderRadius: `0px` }}
                        selected={this.props.selectedIndex === 0}
                    >
                        <ListItemIcon>
                            <IconLogout stroke={1.5} size="1.3rem" />
                        </ListItemIcon>
                        <ListItemText primary={
                            <Typography>Çıkış Yap</Typography>
                        }>
                        </ListItemText>
                    </ListItemButton>
                </a>
                       
            </>
        );
    }
}

export default Navigation;
