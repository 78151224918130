import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MainLayout from 'layout/MainLayout';

// login option 3 routing
const News = Loadable(lazy(() => import('views/EarnPortal/news')))
const NewsAdd = Loadable(lazy(() => import('views/EarnPortal/news/add')))

const Projects = Loadable(lazy(() => import('views/EarnPortal/projects')))
const ProjectsAdd = Loadable(lazy(() => import('views/EarnPortal/projects/add')))

const Partners = Loadable(lazy(() => import('views/EarnPortal/partners')))
const PartnersAdd = Loadable(lazy(() => import('views/EarnPortal/partners/add')))

const Outputs = Loadable(lazy(() => import('views/EarnPortal/outputs')))
const OutputsAdd = Loadable(lazy(() => import('views/EarnPortal/outputs/add')))

const Gallery = Loadable(lazy(() => import('views/EarnPortal/gallery')))
const GalleryAdd = Loadable(lazy(() => import('views/EarnPortal/gallery/add')))

const Settings = Loadable(lazy(() => import('views/EarnPortal/settings')))
const AddSlider = Loadable(lazy(() => import('views/EarnPortal/settings/add-slider')))

const Messages = Loadable(lazy(() => import('views/EarnPortal/messages')))


// ==============================|| AUTHENTICATION ROUTING ||============================== //

const EarnPortalRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            element: <News />,
            path: '/earnportal/news',
        },
        {
            element: <NewsAdd />,
            path: '/earnportal/news/add',
        },
        {
            element: <Projects />,
            path: '/earnportal/projects',
        },
        {
            element: <ProjectsAdd />,
            path: '/earnportal/projects/add',
        },
        {
            element: <Partners />,
            path: '/earnportal/partners',
        },
        {
            element: <PartnersAdd />,
            path: '/earnportal/partners/add',
        },
        {
            element: <Outputs />,
            path: '/earnportal/outputs',
        },
        {
            element: <OutputsAdd />,
            path: '/earnportal/outputs/add',
        },
        {
            element: <Gallery />,
            path: '/earnportal/gallery',
        },
        {
            element: <GalleryAdd />,
            path: '/earnportal/gallery/add',
        },
        {
            element: <Settings />,
            path: '/earnportal/settings',
        },
        {
            element: <AddSlider />,
            path: '/earnportal/settings/add',
        },
        {
            element: <Messages />,
            path: '/earnportal/messages',
        },
    ]
};

export default EarnPortalRoutes;
