import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MainLayout from 'layout/MainLayout';

// login option 3 routing
const Dashboard = Loadable(lazy(() => import('views/Dashboard')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const DashboardRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: '/dashboard',
            element: <Dashboard />
        },
    ]
};

export default DashboardRoutes;
