// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="80%" height="auto" viewBox="0 0 251.622 80.709">
            <g id="Group_3" data-name="Group 3" transform="translate(-880 -514.826)">
                <g id="Group_2" data-name="Group 2" transform="translate(880 514.826)">
                <path id="Path_1" data-name="Path 1" d="M.382.125c.427.142.841.336,1.282.436C1.589.524,1.144.359.79.241L-.686-.225c.36.114.618.193,1.068.35" transform="translate(53.288 1.88)" fill="#892233" />
                <path id="Path_2" data-name="Path 2" d="M.039.013a6.908,6.908,0,0,0,1.146.36s0,0,0-.006a3.173,3.173,0,0,0-.748-.3L.427.056a3.036,3.036,0,0,1,.729.3l0,0A8.2,8.2,0,0,1-.07-.023l.012.008c.03.011.065.017.1.028" transform="translate(51.993 1.648)" fill="#892233" />
                <path id="Path_3" data-name="Path 3" d="M.385.211c.9.481,1.165.632,1.246.651A19.646,19.646,0,0,0-.675-.373a.017.017,0,0,0-.016,0c.016.017.016.017,1.076.589" transform="translate(59.425 4.407)" fill="#892233" />
                <path id="Path_4" data-name="Path 4" d="M.006,0A5.985,5.985,0,0,0,1.283.6l0-.016C1.27.552,1.156.441.569.115h0S.56.106.555.109c.575.313.687.43.7.463l0,.011A5.434,5.434,0,0,1-.01-.006S0,0,0,0H0Z" transform="translate(57.952 4.09)" fill="#892233" />
                <path id="Path_5" data-name="Path 5" d="M.411.307c1.151.857,1.151.857,1.246.914A24.429,24.429,0,0,0-.738-.55c.023.026.023.026,1.149.857" transform="translate(64.679 7.546)" fill="#892233" />
                <path id="Path_6" data-name="Path 6" d="M.315.275a5.408,5.408,0,0,0,1.274.8A7.169,7.169,0,0,0-.528-.494c-.011,0-.026,0-.036,0C-.587-.4.162.162.315.275" transform="translate(63.177 7.025)" fill="#892233" />
                <path id="Path_7" data-name="Path 7" d="M.036.03C.686.539,1.318.852,1.287.651,1.273.589,1.193.437.743.072l0,0C.737.063.735.064.731.066c.593.477.57.614.473.624A3.143,3.143,0,0,1-.065-.054l.011.011A.841.841,0,0,1,.036.03" transform="translate(60.991 6.771)" fill="#892233" />
                <path id="Path_8" data-name="Path 8" d="M.369.369C.93.941,1.383,1.428,1.4,1.446L-.662-.663C-.394-.4-.011-.021.369.369" transform="translate(69.916 11.916)" fill="#892233" />
                <path id="Path_9" data-name="Path 9" d="M.291.346C.638.7,1.322,1.358,1.411,1.3A7.735,7.735,0,0,0-.491-.616c-.006,0-.012,0-.022,0,0,.009-.008.016-.009.022A6.22,6.22,0,0,0,.291.346" transform="translate(68.749 11.339)" fill="#892233" />
                <path id="Path_10" data-name="Path 10" d="M0,.007C.67.714,1.234,1.064,1.265.877,1.278.827,1.248.648.77.13l0,0s0,0-.009-.014c.564.618.542.808.394.785A3.022,3.022,0,0,1-.007-.012S0,0,0,0L0,0Z" transform="translate(66.686 11.04)" fill="#892233" />
                <path id="Path_11" data-name="Path 11" d="M.3.448a10.058,10.058,0,0,0,.992,1.2l.02-.008A11.592,11.592,0,0,0-.512-.8L-.532-.8A10.759,10.759,0,0,0,.3.448" transform="translate(73.132 15.878)" fill="#892233" />
                <path id="Path_12" data-name="Path 12" d="M.208.407c.559.766,1.134,1.268,1.157,1.017A4.565,4.565,0,0,0-.3-.731C-.548-.74-.12-.036.208.407" transform="translate(71.373 15.362)" fill="#892233" />
                <path id="Path_13" data-name="Path 13" d="M.029.042C.566.8,1.255,1.246,1.3.845A1.484,1.484,0,0,0,.97.039.059.059,0,0,0,.962.029c.477.738.335.983.086.92A2.47,2.47,0,0,1-.052-.075l.006.008,0,0c.023.033.051.076.073.1" transform="translate(68.524 15.234)" fill="#892233" />
                <path id="Path_14" data-name="Path 14" d="M.315.111A2.922,2.922,0,0,0-.565.4C-.487.437-.252.392.491.185A12.07,12.07,0,0,1,2.157-.2c-.241.037-.561.075-.8.109C1.16-.065.763,0,.315.111" transform="translate(33.355 0.529)" fill="#892233" />
                <path id="Path_15" data-name="Path 15" d="M.173.092C-.2.2-.363.3-.3.347A3.027,3.027,0,0,0,.9.076c.417-.134.573-.185.811-.238a.015.015,0,0,0-.011,0l-.207.023A8.8,8.8,0,0,0,.173.092" transform="translate(34.94 0.358)" fill="#892233" />
                <path id="Path_16" data-name="Path 16" d="M.061.074c-.167.078-.216.162-.126.2A1.761,1.761,0,0,0,1.167-.116c-.006-.012-.011-.025-.15-.009a2.724,2.724,0,0,0-.956.2" transform="translate(36.641 0.233)" fill="#892233" />
                <path id="Path_17" data-name="Path 17" d="M0,.059C-.063.227.4.341.747.2c.2-.092.234-.22.076-.274A1.067,1.067,0,0,0,.48-.106C.127-.081.022-.006,0,.059" transform="translate(38.397 0.142)" fill="#892233" />
                <path id="Path_18" data-name="Path 18" d="M.037.049c.357.215.983.246.969.09C1,.024.608-.1.1-.088c-.1,0-.221.006-.129.084a.238.238,0,0,0,.07.053" transform="translate(40.114 0.092)" fill="#892233" />
                <path id="Path_19" data-name="Path 19" d="M.135.044c.99.276,1.1.174,1.078.14A3.013,3.013,0,0,0-.167-.077a.183.183,0,0,0-.072,0l0,0A1.5,1.5,0,0,0,.135.044" transform="translate(41.781 0.08)" fill="#892233" />
                <path id="Path_20" data-name="Path 20" d="M.227.046A4.587,4.587,0,0,0,1.385.222a5.671,5.671,0,0,0-1.757-.3c-.011,0-.023,0-.036-.008,0,.011,0,.011.12.033.167.026.343.062.514.1" transform="translate(43.421 0.118)" fill="#892233" />
                <path id="Path_21" data-name="Path 21" d="M.3.046c1.146.192,1.146.192,1.19.195a9.843,9.843,0,0,0-2-.324.028.028,0,0,1-.02,0C-.258-.019.02,0,.3.046" transform="translate(44.991 0.206)" fill="#892233" />
                <path id="Path_22" data-name="Path 22" d="M.285.041C.673.1,1.056.2,1.45.238A10.586,10.586,0,0,0,.292.028l-.8-.1c.235.026.511.067.8.114" transform="translate(46.498 0.358)" fill="#892233" />
                <path id="Path_23" data-name="Path 23" d="M.3.116A4.725,4.725,0,0,0-.54.361,4.014,4.014,0,0,0,.187.187C.9.011,1.371-.1,1.938-.209,1.475-.119.86-.007.4.093.373.1.331.109.3.116" transform="translate(31.89 0.791)" fill="#892233" />
                <path id="Path_24" data-name="Path 24" d="M0,.059A.559.559,0,0,0,.133.025C.309-.022.482-.067.658-.106L0,.062Z" transform="translate(30.265 1.307)" fill="#892233" />
                <path id="Path_25" data-name="Path 25" d="M.308.3C-.3.583-.66.851-.525.92A10.968,10.968,0,0,0,2.812-.527c-.031-.012-.04-.008-.112,0A12.386,12.386,0,0,0,.308.3" transform="translate(29.144 1.659)" fill="#892233" />
                <path id="Path_26" data-name="Path 26" d="M.174.259c-.434.265-.606.5-.4.561A4.385,4.385,0,0,0,2.27-.44c-.016-.033-.111-.025-.176-.023A6.7,6.7,0,0,0,.174.259" transform="translate(31.477 1.392)" fill="#892233" />
                <path id="Path_27" data-name="Path 27" d="M.073.226c-.316.29-.26.52.121.52.743,0,1.825-.861,1.564-1.1A.424.424,0,0,0,1.494-.4,3.058,3.058,0,0,0,.073.226" transform="translate(34.044 1.18)" fill="#892233" />
                <path id="Path_28" data-name="Path 28" d="M.01.2c-.165.4.4.626.959.375.54-.229.687-.754.257-.9a.712.712,0,0,0-.276-.03A1.169,1.169,0,0,0,.01.2" transform="translate(36.749 1.025)" fill="#892233" />
                <path id="Path_29" data-name="Path 29" d="M.005.177C.156.669,1.156.8,1.355.357,1.481.074,1.084-.315.5-.317.033-.281-.048,0,.005.177" transform="translate(39.504 0.929)" fill="#892233" />
                <path id="Path_30" data-name="Path 30" d="M.061.157C.549.622,1.419.725,1.424.4,1.429.122.7-.269.152-.281c-.519.02-.109.422-.09.438" transform="translate(42.223 0.902)" fill="#892233" />
                <path id="Path_31" data-name="Path 31" d="M.14.142C.811.533,1.522.659,1.5.45,1.476.215.484-.224-.1-.255c-.069,0-.249.006-.07.178A1.67,1.67,0,0,0,.14.142" transform="translate(44.864 0.948)" fill="#892233" />
                <path id="Path_32" data-name="Path 32" d="M.213.129c.8.354,1.4.473,1.327.333A4.152,4.152,0,0,0-.295-.232c-.048,0-.111,0-.078.042A2.209,2.209,0,0,0,.213.129" transform="translate(47.372 1.068)" fill="#892233" />
                <path id="Path_33" data-name="Path 33" d="M.268.121A4.591,4.591,0,0,0,1.526.485,5.839,5.839,0,0,0-.427-.208c-.037-.011-.044-.009-.053,0,.016.023.051.072.748.333" transform="translate(49.68 1.267)" fill="#892233" />
                <path id="Path_34" data-name="Path 34" d="M.263.1h0A7.138,7.138,0,0,0,1.5.488,3.824,3.824,0,0,0,.764.183l-.006,0a12.385,12.385,0,0,0-1.2-.352c-.008,0-.019,0-.026,0C-.425-.133.26.1.26.1Z" transform="translate(51.645 1.521)" fill="#892233" />
                <path id="Path_35" data-name="Path 35" d="M.4.3A5.265,5.265,0,0,0-.726.895.2.2,0,0,1-.715.92,34.432,34.432,0,0,0,2.923-.538a.235.235,0,0,0-.055.009A17.684,17.684,0,0,0,.4.3" transform="translate(27.37 1.957)" fill="#892233" />
                <path id="Path_36" data-name="Path 36" d="M.475.3A11.288,11.288,0,0,0-.853.895,14.2,14.2,0,0,0,1.055.15c.724-.285.833-.321,1.864-.688l-.014,0C2.86-.519,1.713-.188.475.3" transform="translate(26.056 2.269)" fill="#892233" />
                <path id="Path_37" data-name="Path 37" d="M0,.294C.672.024,1.332-.27,2.006-.527L0,.289Z" transform="translate(24.259 3.175)" fill="#892233" />
                <path id="Path_38" data-name="Path 38" d="M.184.47c-.609.55-.687.969-.174.962C1.095,1.415,3.245-.382,2.942-.779c-.058-.079-.2-.065-.3-.058A5.983,5.983,0,0,0,.184.47" transform="translate(26.893 3.618)" fill="#892233" />
                <path id="Path_39" data-name="Path 39" d="M.083.423c-.422.548-.252.956.368.9C1.651,1.2,2.969-.332,2.441-.7a.621.621,0,0,0-.368-.061A3.53,3.53,0,0,0,.083.423" transform="translate(30.299 3.303)" fill="#892233" />
                <path id="Path_40" data-name="Path 40" d="M.021.381c-.263.668.4,1.062,1.221.724.981-.4,1.286-1.433.7-1.721a.918.918,0,0,0-.417-.065A1.963,1.963,0,0,0,.021.381" transform="translate(33.97 3.06)" fill="#892233" />
                <path id="Path_41" data-name="Path 41" d="M0,.343c.008.771,1.185,1.084,1.792.469A.843.843,0,0,0,1.112-.613c-.028,0-.069,0-.093,0A1.017,1.017,0,0,0,0,.343" transform="translate(37.8 2.902)" fill="#892233" />
                <path id="Path_42" data-name="Path 42" d="M.023.31c.325.755,1.6,1.037,1.881.4C2.127.226,1.46-.539.588-.556A.583.583,0,0,0,.023.31" transform="translate(41.659 2.836)" fill="#892233" />
                <path id="Path_43" data-name="Path 43" d="M.081.277C.7,1.023,1.871,1.2,1.908.7,1.934.255,1.05-.461.242-.5-.33-.475-.166-.02.081.277" transform="translate(45.439 2.86)" fill="#892233" />
                <path id="Path_44" data-name="Path 44" d="M.151.251c.788.657,1.761.872,1.707.47C1.791.3.663-.4-.036-.451c-.5.006-.059.47.031.564C.039.15.1.206.151.251" transform="translate(49.045 2.983)" fill="#892233" />
                <path id="Path_45" data-name="Path 45" d="M.213.226c.762.515,1.592.8,1.564.54C1.742.445.4-.326-.242-.4c-.067,0-.282,0,0,.274a2.9,2.9,0,0,0,.45.357" transform="translate(52.365 3.203)" fill="#892233" />
                <path id="Path_46" data-name="Path 46" d="M.26.2C1.011.649,1.7.917,1.648.747A5.013,5.013,0,0,0-.387-.362c-.045-.006-.132-.011-.037.09A4,4,0,0,0,.26.2" transform="translate(55.298 3.527)" fill="#892233" />
                <path id="Path_47" data-name="Path 47" d="M.252.162a6.148,6.148,0,0,0,1.272.6l0-.011A3.226,3.226,0,0,0,.82.278l-.006,0A7.894,7.894,0,0,0-.419-.289c-.008,0-.025,0-.033,0,0,.025.014.067.7.453" transform="translate(57.678 3.913)" fill="#892233" />
                <path id="Path_48" data-name="Path 48" d="M.275.461c-.646.473-.936.869-.673.914C.16,1.468,3.128-.458,2.951-.8c-.025-.042-.1-.031-.179-.022A8.731,8.731,0,0,0,.275.461" transform="translate(24.115 4.009)" fill="#892233" />
                <path id="Path_49" data-name="Path 49" d="M.356.447c-.171.107-1.02.667-.994.85C-.384,1.46,2.619-.435,2.86-.77A.066.066,0,0,0,2.854-.8a.277.277,0,0,0-.079.009A12.124,12.124,0,0,0,.356.447" transform="translate(21.968 4.423)" fill="#892233" />
                <path id="Path_50" data-name="Path 50" d="M.415.424a7.366,7.366,0,0,0-1.159.819c.006,0,.009.012.017.019.364-.095,3.42-2,3.439-2.02l-.034,0A19.946,19.946,0,0,0,.415.424" transform="translate(20.448 4.837)" fill="#892233" />
                <path id="Path_51" data-name="Path 51" d="M.155.574c-.229.15-.38.243-.433.287C-.211.826-.209.826.261.538.8.217,1.907-.46,2.988-1.03l-.757.4L.178.565.155.574" transform="translate(19 5.578)" fill="#892233" />
                <path id="Path_52" data-name="Path 52" d="M.181.635c-.632.73-.676,1.279-.1,1.271C1.382,1.9,3.686-.5,3.256-1.047c-.086-.111-.273-.095-.385-.086A5.947,5.947,0,0,0,.181.635" transform="translate(22.249 6.569)" fill="#892233" />
                <path id="Path_53" data-name="Path 53" d="M.088.58c-.5.791-.209,1.336.623,1.168,1.467-.3,2.8-2.213,2.168-2.7a.729.729,0,0,0-.463-.087A3.967,3.967,0,0,0,.088.58" transform="translate(26.188 6.17)" fill="#892233" />
                <path id="Path_54" data-name="Path 54" d="M.029.53c-.339.888.419,1.406,1.423.966,1.2-.531,1.764-2.051.9-2.392A1.116,1.116,0,0,0,1.92-.951,2.533,2.533,0,0,0,.029.53" transform="translate(30.574 5.856)" fill="#892233" />
                <path id="Path_55" data-name="Path 55" d="M0,.48c-.095,1,1.124,1.425,2.01.715C2.869.508,2.662-.717,1.67-.857a1.89,1.89,0,0,0-.23,0A1.6,1.6,0,0,0,0,.48" transform="translate(35.242 5.628)" fill="#892233" />
                <path id="Path_56" data-name="Path 56" d="M.006.435A1.265,1.265,0,0,0,2.222.977,1.258,1.258,0,0,0,.992-.78,1.012,1.012,0,0,0,.006.435" transform="translate(40.043 5.5)" fill="#892233" />
                <path id="Path_57" data-name="Path 57" d="M.039.392C.481,1.308,1.9,1.669,2.172.922,2.4.324,1.579-.67.6-.7c-.765.048-.752.7-.562,1.1" transform="translate(44.82 5.488)" fill="#892233" />
                <path id="Path_58" data-name="Path 58" d="M.089.351C.715,1.2,2,1.547,2.062.883,2.1.343,1.139-.573.276-.629-.369-.6-.18-.012.089.351" transform="translate(49.424 5.577)" fill="#892233" />
                <path id="Path_59" data-name="Path 59" d="M.144.314C.856,1.056,1.919,1.4,1.89.891,1.862.428.772-.489.012-.564-.5-.556-.18-.024.144.314" transform="translate(53.715 5.786)" fill="#892233" />
                <path id="Path_60" data-name="Path 60" d="M.191.279c.685.595,1.5.947,1.506.642C1.7.564.5-.4-.183-.5c-.369,0,0,.417.129.548C.008.115.11.205.191.279" transform="translate(57.542 6.104)" fill="#892233" />
                <path id="Path_61" data-name="Path 61" d="M.191.216h0l0,.006c.665.534,1.364.9,1.333.685,0-.031-.056-.195-.54-.575l0,0A4.052,4.052,0,0,0-.259-.387c-.047,0-.167-.017.011.193a3.41,3.41,0,0,0,.439.41" transform="translate(60.722 6.491)" fill="#892233" />
                <path id="Path_62" data-name="Path 62" d="M.263.608c-.7.676-.942,1.2-.547,1.2C.564,1.793,3.5-.767,3.026-1.072a.267.267,0,0,0-.182-.008A7.484,7.484,0,0,0,.263.608" transform="translate(19.228 7.037)" fill="#892233" />
                <path id="Path_63" data-name="Path 63" d="M.337.576C-.384,1.2-.774,1.7-.532,1.7A10.56,10.56,0,0,0,2.827-1.016c-.025-.025-.036-.02-.1,0A10.171,10.171,0,0,0,.337.576" transform="translate(16.995 7.513)" fill="#892233" />
                <path id="Path_64" data-name="Path 64" d="M.391.544A7.754,7.754,0,0,0-.7,1.572.111.111,0,0,0-.685,1.6,28.92,28.92,0,0,0,2.565-.957L2.557-.976l-.028.011A16.624,16.624,0,0,0,.391.544" transform="translate(15.522 7.989)" fill="#892233" />
                <path id="Path_65" data-name="Path 65" d="M.007.823a.04.04,0,0,1-.02.012C1,.1,1.927-.745,2.946-1.477L1.689-.549.026.806A.026.026,0,0,0,.007.823" transform="translate(14.024 9.09)" fill="#892233" />
                <path id="Path_66" data-name="Path 66" d="M.087.787C-.488,1.921,0,2.658,1.1,2.306,2.877,1.744,4.212-.848,3.3-1.335A.808.808,0,0,0,2.812-1.4,4.438,4.438,0,0,0,.087.787" transform="translate(21.258 10.325)" fill="#892233" />
                <path id="Path_67" data-name="Path 67" d="M.034.729c-.4,1.227.519,1.92,1.763,1.319,1.595-.763,2.275-2.967,1.02-3.323A1.334,1.334,0,0,0,2.4-1.3,3.206,3.206,0,0,0,.034.729" transform="translate(26.239 9.947)" fill="#892233" />
                <path id="Path_68" data-name="Path 68" d="M.006.672C-.173,1.966,1.161,2.584,2.343,1.756c1.261-.881,1.2-2.761-.1-2.95a2.125,2.125,0,0,0-.3-.012A2.31,2.31,0,0,0,.006.672" transform="translate(31.681 9.662)" fill="#892233" />
                <path id="Path_69" data-name="Path 69" d="M0,.614a1.517,1.517,0,0,0,2.677.839A1.538,1.538,0,0,0,1.5-1.1,1.637,1.637,0,0,0,0,.614" transform="translate(37.391 9.476)" fill="#892233" />
                <path id="Path_70" data-name="Path 70" d="M.018.561C.369,1.87,2.124,2.338,2.7,1.28A1.732,1.732,0,0,0,1.071-1.006,1.168,1.168,0,0,0,.018.561" transform="translate(43.181 9.401)" fill="#892233" />
                <path id="Path_71" data-name="Path 71" d="M.053.5c.54,1.182,2.166,1.638,2.482.7C2.8.435,1.833-.851.685-.906-.226-.858-.19-.017.053.5" transform="translate(48.847 9.445)" fill="#892233" />
                <path id="Path_72" data-name="Path 72" d="M.1.453c.642,1.018,2,1.5,2.193.768C2.468.551,1.38-.735.354-.813-.4-.788-.2-.02.1.453" transform="translate(54.192 9.607)" fill="#892233" />
                <path id="Path_73" data-name="Path 73" d="M.139.4c.679.881,1.761,1.347,1.867.8C2.11.638.959-.623.078-.72-.5-.711-.195-.029.139.4" transform="translate(59.034 9.886)" fill="#892233" />
                <path id="Path_74" data-name="Path 74" d="M.177.354c.665.748,1.492,1.182,1.529.794C1.741.714.563-.521-.136-.636c-.417-.011-.031.6.313.99" transform="translate(63.202 10.287)" fill="#892233" />
                <path id="Path_75" data-name="Path 75" d="M.175.276c.57.62,1.216,1.07,1.258.875C1.459,1.039,1.258.737.939.4l0,0h0A3.407,3.407,0,0,0-.231-.495c-.29-.022.26.61.322.677A.878.878,0,0,0,.172.27Z" transform="translate(66.496 10.745)" fill="#892233" />
                <path id="Path_76" data-name="Path 76" d="M.151.749c-.6.9-.553,1.568.107,1.475,1.33-.179,3.544-2.931,2.823-3.5a.455.455,0,0,0-.357-.053A5.278,5.278,0,0,0,.151.749" transform="translate(17.215 10.817)" fill="#892233" />
                <path id="Path_77" data-name="Path 77" d="M.219.7c-.657.839-.81,1.456-.338,1.374C.8,1.9,3.208-.877,2.739-1.234a.227.227,0,0,0-.184-.017A6.335,6.335,0,0,0,.219.7" transform="translate(14.118 11.322)" fill="#892233" />
                <path id="Path_78" data-name="Path 78" d="M.28.653c-.614.7-.928,1.265-.719,1.275.491.017,3.18-2.9,2.848-3.094-.02-.012-.058,0-.079,0A8.2,8.2,0,0,0,.28.653" transform="translate(11.953 11.824)" fill="#892233" />
                <path id="Path_79" data-name="Path 79" d="M.329.6A6.074,6.074,0,0,0-.59,1.772,25.051,25.051,0,0,0,2.1-1.074a.188.188,0,0,0-.03,0A14.177,14.177,0,0,0,.329.6" transform="translate(10.674 12.309)" fill="#892233" />
                <path id="Path_80" data-name="Path 80" d="M.079.918c-.587,1.417.053,2.257,1.3,1.707,1.764-.791,3.1-3.8,1.88-4.233a1.373,1.373,0,0,0-.428-.039A4.529,4.529,0,0,0,.079.918" transform="translate(16.601 14.879)" fill="#892233" />
                <path id="Path_81" data-name="Path 81" d="M.033.861c-.4,1.414.6,2.222,1.911,1.55C3.683,1.52,4.407-1.075,3.037-1.5a1.42,1.42,0,0,0-.469-.047A3.524,3.524,0,0,0,.033.861" transform="translate(21.843 14.48)" fill="#892233" />
                <path id="Path_82" data-name="Path 82" d="M.008.8C-.206,2.3,1.242,3.023,2.544,2.073,3.988,1.03,3.993-1.2,2.554-1.42a1.831,1.831,0,0,0-.333-.014A2.719,2.719,0,0,0,.008.8" transform="translate(27.749 14.162)" fill="#892233" />
                <path id="Path_83" data-name="Path 83" d="M0,.737a1.634,1.634,0,0,0,2.931.981A1.754,1.754,0,0,0,1.836-1.323,2.085,2.085,0,0,0,0,.737" transform="translate(34.089 13.933)" fill="#892233" />
                <path id="Path_84" data-name="Path 84" d="M.007.675a1.651,1.651,0,0,0,2.87.948A1.809,1.809,0,0,0,1.429-1.211,1.582,1.582,0,0,0,.007.675" transform="translate(40.645 13.81)" fill="#892233" />
                <path id="Path_85" data-name="Path 85" d="M.025.611c.43,1.419,2.208,1.936,2.758.8A1.949,1.949,0,0,0,1.042-1.1,1.224,1.224,0,0,0,.025.611" transform="translate(47.166 13.788)" fill="#892233" />
                <path id="Path_86" data-name="Path 86" d="M.054.549c.519,1.194,2.007,1.729,2.414.863.382-.8-.59-2.327-1.785-2.4C-.236-.934-.189-.023.054.549" transform="translate(53.42 13.892)" fill="#892233" />
                <path id="Path_87" data-name="Path 87" d="M.085.488C.681,1.572,1.937,2.07,2.15,1.3A2.258,2.258,0,0,0,.372-.875C-.362-.842-.194-.026.085.488" transform="translate(59.178 14.109)" fill="#892233" />
                <path id="Path_88" data-name="Path 88" d="M.115.427c.584.909,1.543,1.384,1.66.824A2.416,2.416,0,0,0,.112-.766c-.543.012-.285.741,0,1.193" transform="translate(64.229 14.439)" fill="#892233" />
                <path id="Path_89" data-name="Path 89" d="M.115.331h0c.579.863,1.289,1.274,1.335.9a1.369,1.369,0,0,0-.324-.8A2.451,2.451,0,0,0-.039-.594c-.271,0-.212.352.154.925" transform="translate(68.351 14.817)" fill="#892233" />
                <path id="Path_90" data-name="Path 90" d="M.131.857c-.59,1.14-.453,1.912.3,1.7C1.851,2.15,3.771-1.035,2.883-1.5a.63.63,0,0,0-.293-.034A5.077,5.077,0,0,0,.131.857" transform="translate(12.618 15.365)" fill="#892233" />
                <path id="Path_91" data-name="Path 91" d="M.189.8c-.578.962-.7,1.677-.273,1.59.869-.159,3.04-3.334,2.577-3.772-.048-.053-.121-.039-.178-.037A5.908,5.908,0,0,0,.189.8" transform="translate(9.715 15.848)" fill="#892233" />
                <path id="Path_92" data-name="Path 92" d="M.246.732C-.321,1.593-.6,2.26-.351,2.2c.5-.14,2.7-3.278,2.445-3.5-.017-.019-.05,0-.069-.009A8.16,8.16,0,0,0,.246.732" transform="translate(7.836 16.319)" fill="#892233" />
                <path id="Path_93" data-name="Path 93" d="M.3.667A10.748,10.748,0,0,0-.535,1.995c.006,0,.011,0,.014.011.235-.2,2.185-3,2.266-3.2A.048.048,0,0,1,1.731-1.2,19.685,19.685,0,0,0,.3.667" transform="translate(6.923 16.758)" fill="#892233" />
                <path id="Path_94" data-name="Path 94" d="M.026,1.043c-.4,1.82.913,2.788,2.4,1.782,1.889-1.268,2.344-4.374.685-4.681a1.847,1.847,0,0,0-.371-.009A3.812,3.812,0,0,0,.026,1.043" transform="translate(17.04 20.278)" fill="#892233" />
                <path id="Path_95" data-name="Path 95" d="M.007.984C-.21,2.839,1.424,3.72,2.919,2.559c1.654-1.28,1.651-4.016-.009-4.3a1.814,1.814,0,0,0-.391-.017A3.171,3.171,0,0,0,.007.984" transform="translate(23.227 19.974)" fill="#892233" />
                <path id="Path_96" data-name="Path 96" d="M0,.92A1.853,1.853,0,0,0,3.241,2.3c1.434-1.347.916-3.847-.818-3.946-.062,0-.137.006-.2,0A2.628,2.628,0,0,0,0,.92" transform="translate(30.038 19.735)" fill="#892233" />
                <path id="Path_97" data-name="Path 97" d="M0,.853A1.9,1.9,0,0,0,3.365,2.024,2.161,2.161,0,0,0,1.871-1.53,2.156,2.156,0,0,0,0,.853" transform="translate(37.231 19.576)" fill="#892233" />
                <path id="Path_98" data-name="Path 98" d="M.013.781C.337,2.439,2.295,3.131,3.195,1.9A2.177,2.177,0,0,0,1.5-1.4,1.734,1.734,0,0,0,.013.781" transform="translate(44.529 19.511)" fill="#892233" />
                <path id="Path_99" data-name="Path 99" d="M.029.71C.47,2.252,2.219,2.895,2.91,1.767A2.218,2.218,0,0,0,1.121-1.274C0-1.193-.192-.066.029.71" transform="translate(51.654 19.544)" fill="#892233" />
                <path id="Path_100" data-name="Path 100" d="M.05.634c.514,1.4,2.024,1.993,2.485,1A2.266,2.266,0,0,0,.76-1.138C-.21-1.076-.193-.015.05.634" transform="translate(58.331 19.671)" fill="#892233" />
                <path id="Path_101" data-name="Path 101" d="M.071.562c.512,1.17,1.668,1.752,2,1.014A2.3,2.3,0,0,0,.444-1.009C-.312-.965-.186-.026.071.562" transform="translate(64.309 19.908)" fill="#892233" />
                <path id="Path_102" data-name="Path 102" d="M.052.97C-.4,2.448.231,3.362,1.32,2.815c1.649-.83,2.728-3.99,1.537-4.507a1.009,1.009,0,0,0-.4-.048C1.3-1.516.354-.024.052.97" transform="translate(12.056 20.703)" fill="#892233" />
                <path id="Path_103" data-name="Path 103" d="M.09.893C-.387,2.159-.16,3,.569,2.651c1.3-.618,2.663-3.945,1.733-4.244A.691.691,0,0,0,2.114-1.6C1.287-1.394.432-.018.09.893" transform="translate(8.296 21.121)" fill="#892233" />
                <path id="Path_104" data-name="Path 104" d="M.134.815C-.33,1.892-.369,2.651.051,2.467c.8-.341,2.353-3.638,1.841-3.918a.182.182,0,0,0-.107,0A5.032,5.032,0,0,0,.134.815" transform="translate(5.705 21.52)" fill="#892233" />
                <path id="Path_105" data-name="Path 105" d="M.183.74c-.238.508-.615,1.4-.483,1.521C-.082,2.251.545,1.056.728.69a6.749,6.749,0,0,0,.788-2,.065.065,0,0,0-.022-.017.048.048,0,0,1-.03,0A7.568,7.568,0,0,0,.183.74" transform="translate(4.22 21.896)" fill="#892233" />
                <path id="Path_106" data-name="Path 106" d="M0,1.156C.57.1,1.054-1,1.607-2.075l0,.006L.475.125,0,1.15Z" transform="translate(3.337 23.111)" fill="#892233" />
                <path id="Path_107" data-name="Path 107" d="M.014,1.127c-.285,1.979,1.018,3.034,2.44,1.979C4.281,1.742,4.586-1.639,2.909-2a1.582,1.582,0,0,0-.374-.022C1.076-1.795.184-.041.014,1.127" transform="translate(12.853 26.075)" fill="#892233" />
                <path id="Path_108" data-name="Path 108" d="M0,1.073c-.151,2,1.535,2.978,3.007,1.754C4.786,1.335,4.587-1.812,2.7-1.926c-.064,0-.142.008-.206,0A3.233,3.233,0,0,0,0,1.073" transform="translate(18.983 25.832)" fill="#892233" />
                <path id="Path_109" data-name="Path 109" d="M0,1.012A1.934,1.934,0,0,0,3.365,2.53C4.909,1.024,4.387-1.7,2.531-1.812c-.064-.008-.146,0-.21,0A2.823,2.823,0,0,0,0,1.012" transform="translate(25.95 25.63)" fill="#892233" />
                <path id="Path_110" data-name="Path 110" d="M0,.943A1.959,1.959,0,0,0,3.518,2.225c1.165-1.4.522-3.893-1.447-3.917A2.43,2.43,0,0,0,0,.943" transform="translate(33.47 25.478)" fill="#892233" />
                <path id="Path_111" data-name="Path 111" d="M.007.87C.258,2.723,2.291,3.463,3.36,2.089a2.289,2.289,0,0,0-1.6-3.65A2.065,2.065,0,0,0,.007.87" transform="translate(41.251 25.385)" fill="#892233" />
                <path id="Path_112" data-name="Path 112" d="M.015.795C.35,2.483,2.2,3.173,3.069,1.935A2.236,2.236,0,0,0,1.421-1.426,1.737,1.737,0,0,0,.015.795" transform="translate(48.987 25.367)" fill="#892233" />
                <path id="Path_113" data-name="Path 113" d="M.024.713c.388,1.525,2,2.166,2.651,1.057A2.155,2.155,0,0,0,1.08-1.28C.024-1.2-.172-.064.024.713" transform="translate(56.373 25.422)" fill="#892233" />
                <path id="Path_114" data-name="Path 114" d="M.035.635C.418,1.923,1.653,2.541,2.161,1.709,2.7.823,1.936-1.055.745-1.139-.1-1.077-.174-.063.035.635" transform="translate(63.121 25.553)" fill="#892233" />
                <path id="Path_115" data-name="Path 115" d="M.031,1.035c-.344,1.705.383,2.7,1.422,1.95,1.592-1.157,2.176-4.739.8-4.842-.039,0-.093,0-.137,0C1.045-1.646.25-.035.031,1.035" transform="translate(8.236 26.398)" fill="#892233" />
                <path id="Path_116" data-name="Path 116" d="M.056.947C-.288,2.3-.039,3.22.576,2.886c1.076-.593,2.107-4.216,1.3-4.563a.442.442,0,0,0-.168-.02C.984-1.511.3-.039.056.947" transform="translate(4.909 26.706)" fill="#892233" />
                <path id="Path_117" data-name="Path 117" d="M.091.858C-.241,2-.25,2.83.077,2.666c.634-.319,1.816-4.091,1.314-4.2-.014-.008-.036,0-.053,0A5.085,5.085,0,0,0,.091.858" transform="translate(2.796 26.98)" fill="#892233" />
                <path id="Path_118" data-name="Path 118" d="M.141.774A6.109,6.109,0,0,0-.244,2.419s.008.009.012.008A15.93,15.93,0,0,0,1.041-1.383s-.016-.008-.019-.006A9.961,9.961,0,0,0,.141.774" transform="translate(1.819 27.224)" fill="#892233" />
                <path id="Path_119" data-name="Path 119" d="M0,1.209c0,2.247,1.828,3.4,3.275,2.056C5.061,1.6,4.645-2.008,2.643-2.167c-.067,0-.156,0-.22,0C.762-1.96.009-.068,0,1.209" transform="translate(14.834 32.797)" fill="#892233" />
                <path id="Path_120" data-name="Path 120" d="M0,1.155c.079,2.3,2.211,3.331,3.677,1.783C5.247,1.286,4.735-2.039,2.4-2.073.672-1.885-.047-.072,0,1.155" transform="translate(21.722 32.696)" fill="#892233" />
                <path id="Path_121" data-name="Path 121" d="M0,1.092C.171,3.382,2.529,4.3,3.888,2.6c1.3-1.62.586-4.519-1.607-4.56A2.762,2.762,0,0,0,0,1.092" transform="translate(29.33 32.6)" fill="#892233" />
                <path id="Path_122" data-name="Path 122" d="M.005,1.023C.233,3.148,2.5,4.013,3.746,2.44,4.964.9,4.212-1.788,2.073-1.835A2.48,2.48,0,0,0,.005,1.023" transform="translate(37.376 32.53)" fill="#892233" />
                <path id="Path_123" data-name="Path 123" d="M0,1.111C-.086,3.065,1.117,4.154,2.258,3.159c1.579-1.38,1.49-4.98-.128-5.148-.048,0-.117,0-.168,0C.7-1.8.053-.062,0,1.111" transform="translate(9.315 32.992)" fill="#892233" />
                <path id="Path_124" data-name="Path 124" d="M.006,1.01C-.129,2.678.561,3.7,1.35,2.994c1.19-1.053,1.388-4.645.26-4.806-.031,0-.076,0-.114,0C.617-1.642.095-.058.006,1.01" transform="translate(5.179 33.159)" fill="#892233" />
                <path id="Path_125" data-name="Path 125" d="M.015.913c-.15,1.322.118,2.252.564,1.956.825-.551,1.307-4.336.57-4.5a.253.253,0,0,0-.069,0C.527-1.495.122-.062.015.913" transform="translate(2.368 33.292)" fill="#892233" />
                <path id="Path_126" data-name="Path 126" d="M.034.821c-.156,1.1-.114,1.926.086,1.8.413-.26.983-3.929.64-4.086a.259.259,0,0,1-.034,0A5.06,5.06,0,0,0,.034.821" transform="translate(0.79 33.39)" fill="#892233" />
                <path id="Path_127" data-name="Path 127" d="M.007,1.236c-.008.072-.019.139-.02.2.058-.145.6-3.643.6-3.655-.017.061-.031.128-.044.19L.026,1.091l-.019.134Z" transform="translate(0.204 34.355)" fill="#892233" />
                <path id="Path_128" data-name="Path 128" d="M.36,0,.352,0c-.333.139-.667.269-1,.4Z" transform="translate(56.494 77.458)" fill="#892233" />
                <path id="Path_129" data-name="Path 129" d="M.22.06c-.5.2-.581.234-.615.254C-.372.316.156.147.793-.1h0l.008,0A12.223,12.223,0,0,1-.369.308,5.1,5.1,0,0,1,.324.02l-.006,0-.006,0L.22.06" transform="translate(53.899 78.357)" fill="#892233" />
                <path id="Path_130" data-name="Path 130" d="M0,1.234C.183,3.553,1.962,4.76,3.223,3.422,4.757,1.807,4.252-2.136,2-2.214.453-2.033-.1-.09,0,1.234" transform="translate(11.613 39.337)" fill="#892233" />
                <path id="Path_131" data-name="Path 131" d="M.005,1.188C.228,3.53,2.349,4.629,3.67,3.088c1.453-1.693.777-5.148-1.511-5.219C.476-1.955-.113-.08.005,1.188" transform="translate(18.12 39.373)" fill="#892233" />
                <path id="Path_132" data-name="Path 132" d="M.006,1.132c.246,2.327,2.607,3.3,3.882,1.593,1.263-1.669.473-4.7-1.713-4.756C.466-1.863-.125-.083.006,1.132" transform="translate(25.534 39.385)" fill="#892233" />
                <path id="Path_133" data-name="Path 133" d="M.006,1.068C.259,3.3,2.515,4.189,3.778,2.549,4.991.979,4.217-1.861,2.1-1.916A2.557,2.557,0,0,0,.006,1.068" transform="translate(33.531 39.377)" fill="#892233" />
                <path id="Path_134" data-name="Path 134" d="M0,1.127C.118,3.155,1.256,4.29,2.183,3.3c1.235-1.325.98-5.229-.73-5.324C.341-1.872-.069-.092,0,1.127" transform="translate(6.716 39.37)" fill="#892233" />
                <path id="Path_135" data-name="Path 135" d="M0,1.019C.063,2.729.752,3.813,1.34,3.134c.863-1,.8-4.862-.36-4.963C.241-1.7-.035-.08,0,1.019" transform="translate(3.196 39.354)" fill="#892233" />
                <path id="Path_136" data-name="Path 136" d="M0,.918c.019,1.347.324,2.33.634,2.059.477-.416.695-4.5-.067-4.625C.147-1.539-.014-.077,0,.918" transform="translate(0.99 39.302)" fill="#892233" />
                <path id="Path_137" data-name="Path 137" d="M0,.823a5.921,5.921,0,0,0,.165,1.9,12.857,12.857,0,0,0,.062-4.2A7.34,7.34,0,0,0,0,.823" transform="translate(0 39.213)" fill="#892233" />
                <path id="Path_138" data-name="Path 138" d="M.025,1.21C.511,3.7,2.872,4.772,3.977,3.008s.069-5.061-2.118-5.18C.17-2.027-.229-.1.025,1.21" transform="translate(15.144 46.758)" fill="#892233" />
                <path id="Path_139" data-name="Path 139" d="M.018,1.163C.44,3.53,2.871,4.532,4.078,2.834S4.3-1.989,2.032-2.087C.269-1.934-.2-.089.018,1.163" transform="translate(22.101 46.915)" fill="#892233" />
                <path id="Path_140" data-name="Path 140" d="M.013,1.106C.363,3.336,2.744,4.258,4,2.647s.355-4.554-1.9-4.631A2.529,2.529,0,0,0,.013,1.106" transform="translate(29.764 47.011)" fill="#892233" />
                <path id="Path_141" data-name="Path 141" d="M0,.952C.049,3.106,2.174,4.28,3.557,2.916s.911-4.262-1.2-4.622A2.41,2.41,0,0,0,0,.952" transform="translate(38.33 46.73)" fill="#892233" />
                <path id="Path_142" data-name="Path 142" d="M.029,1.1C.456,3.276,2.158,4.345,3.013,2.97c.948-1.5.055-4.821-1.705-4.951C.034-1.863-.208-.1.029,1.1" transform="translate(9.542 46.648)" fill="#892233" />
                <path id="Path_143" data-name="Path 143" d="M.033,1C.377,2.779,1.411,3.824,2.015,3,2.825,1.9,2.13-1.645.8-1.8-.076-1.7-.173-.1.033,1" transform="translate(5.325 46.48)" fill="#892233" />
                <path id="Path_144" data-name="Path 144" d="M.041.907C.31,2.4.963,3.389,1.307,2.832c.492-.8-.076-4.3-.925-4.459C-.1-1.552-.174-.263.041.907" transform="translate(2.435 46.264)" fill="#892233" />
                <path id="Path_145" data-name="Path 145" d="M.053.811c.2,1.184.522,2.068.684,1.866.232-.3-.209-3.951-.679-4.132C-.171-1.4-.121-.236.053.811" transform="translate(0.791 46.004)" fill="#892233" />
                <path id="Path_146" data-name="Path 146" d="M.073.614l.3,1.838c.012.053.022.1.034.15C.417,2.43-.126-1.088-.129-1.1a.9.9,0,0,0,.011.188Z" transform="translate(0.253 45.526)" fill="#892233" />
                <path id="Path_147" data-name="Path 147" d="M.063,1.1c.712,2.308,2.9,3.342,3.714,1.747.752-1.473-.321-4.663-2.447-4.831C-.172-1.89-.312-.121.063,1.1" transform="translate(13.233 52.939)" fill="#892233" />
                <path id="Path_148" data-name="Path 148" d="M.042,1.065C.635,3.228,2.959,4.19,3.924,2.675c.986-1.551-.174-4.46-2.288-4.586C0-1.8-.28-.1.042,1.065" transform="translate(19.537 53.239)" fill="#892233" />
                <path id="Path_149" data-name="Path 149" d="M.025,1.018c.5,2.2,2.939,3,3.949,1.293A2.838,2.838,0,0,0,1.819-1.827,2.162,2.162,0,0,0,.025,1.018" transform="translate(26.666 53.448)" fill="#892233" />
                <path id="Path_150" data-name="Path 150" d="M.013.961c.358,2.057,2.665,2.78,3.742,1.16A2.488,2.488,0,0,0,1.911-1.725,2.192,2.192,0,0,0,.013.961" transform="translate(34.308 53.562)" fill="#892233" />
                <path id="Path_151" data-name="Path 151" d="M.082,1.005c.612,1.9,2.07,2.96,2.682,1.951.744-1.224-.433-4.574-2-4.761C-.312-1.737-.285-.119.082,1.005" transform="translate(8.435 52.665)" fill="#892233" />
                <path id="Path_152" data-name="Path 152" d="M.1.911C.645,2.558,1.636,3.563,2,2.839c.483-.978-.595-4.27-1.688-4.473C-.392-1.587-.233-.117.1.911" transform="translate(4.936 52.324)" fill="#892233" />
                <path id="Path_153" data-name="Path 153" d="M.128.821c.425,1.305,1.029,2.25,1.227,1.917.308-.5-.693-3.993-1.389-4.211C-.385-1.445-.221-.26.128.821" transform="translate(2.682 51.937)" fill="#892233" />
                <path id="Path_154" data-name="Path 154" d="M.167.738a8.35,8.35,0,0,0,.712,1.8.113.113,0,0,1,.028-.017A16.807,16.807,0,0,0-.277-1.325,7.439,7.439,0,0,0,.167.738" transform="translate(1.587 51.517)" fill="#892233" />
                <path id="Path_155" data-name="Path 155" d="M.094,1C1,3.164,3.431,4.056,4.032,2.433c.5-1.35-.746-4.052-2.809-4.223C-.334-1.731-.376-.122.094,1" transform="translate(17.916 59.557)" fill="#892233" />
                <path id="Path_156" data-name="Path 156" d="M.055.96c.707,2.054,3.138,2.851,3.937,1.3A2.993,2.993,0,0,0,1.517-1.722C-.1-1.63-.311-.1.055.96" transform="translate(24.294 59.897)" fill="#892233" />
                <path id="Path_157" data-name="Path 157" d="M.027.918C.524,2.848,2.849,3.564,3.8,2.074A2.535,2.535,0,0,0,1.731-1.648,1.928,1.928,0,0,0,.027.918" transform="translate(31.251 60.114)" fill="#892233" />
                <path id="Path_158" data-name="Path 158" d="M.01.868a1.931,1.931,0,0,0,3.454,1.02A2.152,2.152,0,0,0,1.852-1.557,2.032,2.032,0,0,0,.01.868" transform="translate(38.532 60.21)" fill="#892233" />
                <path id="Path_159" data-name="Path 159" d="M0,.812a1.631,1.631,0,0,0,2.9,1.037C4.03.672,3.623-1.432,1.882-1.457A2.135,2.135,0,0,0,0,.812" transform="translate(45.85 60.186)" fill="#892233" />
                <path id="Path_160" data-name="Path 160" d="M0,.753C-.072,2.137,1.214,2.715,2.287,1.779c1.244-1.085,1.061-3.04-.3-3.13l-.154,0A2.222,2.222,0,0,0,0,.753" transform="translate(52.927 60.054)" fill="#892233" />
                <path id="Path_161" data-name="Path 161" d="M.128.908c.9,1.922,2.755,2.836,3.16,1.557.378-1.205-.919-3.893-2.61-4.1C-.5-1.6-.352-.121.128.908" transform="translate(12.706 59.203)" fill="#892233" />
                <path id="Path_162" data-name="Path 162" d="M.165.829C.939,2.388,2.228,3.335,2.51,2.542c.344-.992-1.1-3.812-2.3-4.03C-.6-1.472-.3-.125.165.829" transform="translate(8.716 58.773)" fill="#892233" />
                <path id="Path_163" data-name="Path 163" d="M.2.75c.64,1.274,1.506,2.177,1.68,1.761C2.135,1.9.694-1.109-.137-1.346c-.442,0-.17,1.09.338,2.1" transform="translate(5.914 58.275)" fill="#892233" />
                <path id="Path_164" data-name="Path 164" d="M.242.678c.5,1,1.059,1.82,1.152,1.691A10,10,0,0,0-.376-1.216c-.2-.006.154.972.618,1.894" transform="translate(4.238 57.741)" fill="#892233" />
                <path id="Path_165" data-name="Path 165" d="M.372.8.893,1.805C.887,1.79.266.587-.667-1.434Z" transform="translate(3.768 57.524)" fill="#892233" />
                <path id="Path_166" data-name="Path 166" d="M.165.862c1.056,1.8,3.14,2.68,3.538,1.5.382-1.142-1.14-3.7-3.018-3.91C-.643-1.53-.418-.125.165.862" transform="translate(17.309 64.531)" fill="#892233" />
                <path id="Path_167" data-name="Path 167" d="M.1.83c.9,1.82,3.105,2.554,3.619,1.2C4.152.914,2.951-1.339,1.064-1.49-.374-1.443-.366-.1.1.83" transform="translate(22.867 64.983)" fill="#892233" />
                <path id="Path_168" data-name="Path 168" d="M.051.8C.732,2.592,2.987,3.169,3.6,1.71A2.506,2.506,0,0,0,1.342-1.431C-.1-1.353-.282-.078.051.8" transform="translate(29.061 65.3)" fill="#892233" />
                <path id="Path_169" data-name="Path 169" d="M.019.756c.414,1.676,2.506,2.171,3.3.782A2,2,0,0,0,1.558-1.356,1.636,1.636,0,0,0,.019.756" transform="translate(35.63 65.48)" fill="#892233" />
                <path id="Path_170" data-name="Path 170" d="M0,.709a1.569,1.569,0,0,0,2.836.8A1.642,1.642,0,0,0,1.685-1.272,1.8,1.8,0,0,0,0,.709" transform="translate(42.336 65.521)" fill="#892233" />
                <path id="Path_171" data-name="Path 171" d="M0,.662c-.055,1.288,1.268,1.752,2.281.8,1.059-.994.822-2.574-.4-2.651-.047,0-.107,0-.153,0A2,2,0,0,0,0,.662" transform="translate(48.927 65.429)" fill="#892233" />
                <path id="Path_172" data-name="Path 172" d="M.01.612c-.2,1.05.638,1.517,1.567.877C2.714.708,2.941-.9,1.943-1.081A2.207,2.207,0,0,0,1.694-1.1,2.187,2.187,0,0,0,.01.612" transform="translate(55.171 65.222)" fill="#892233" />
                <path id="Path_173" data-name="Path 173" d="M.029.556c-.276.832.171,1.275.9.891C1.981.889,2.6-.693,1.864-.969A.605.605,0,0,0,1.59-.992,2.329,2.329,0,0,0,.029.556" transform="translate(60.837 64.911)" fill="#892233" />
                <path id="Path_174" data-name="Path 174" d="M0,.755c-.086,1.02.526,1.464,1.229.888,1.02-.833,1.283-2.789.4-2.987a.649.649,0,0,0-.31.02A2.464,2.464,0,0,0,0,.755" transform="translate(60.063 59.99)" fill="#892233" />
                <path id="Path_175" data-name="Path 175" d="M.221.791c1.02,1.578,2.627,2.471,2.812,1.565.2-.95-1.419-3.534-2.861-3.776C-.783-1.429-.375-.131.221.791" transform="translate(12.997 64.069)" fill="#892233" />
                <path id="Path_176" data-name="Path 176" d="M.27.722C1.159,2.03,2.31,2.916,2.431,2.385c.153-.657-1.56-3.417-2.638-3.68C-.773-1.317-.4-.266.27.722" transform="translate(9.783 63.529)" fill="#892233" />
                <path id="Path_177" data-name="Path 177" d="M.317.66C1.04,1.7,1.859,2.544,1.941,2.324,2.071,1.969.208-.9-.472-1.183c-.307-.028.159.928.79,1.842" transform="translate(7.625 62.933)" fill="#892233" />
                <path id="Path_178" data-name="Path 178" d="M.357.6A13.808,13.808,0,0,0,1.529,2.161,31.285,31.285,0,0,0-.639-1.075,11.37,11.37,0,0,0,.357.6" transform="translate(6.487 62.307)" fill="#892233" />
                <path id="Path_179" data-name="Path 179" d="M.181.727c1.07,1.456,3.238,2.359,3.445,1.142.167-1-1.294-2.987-3.037-3.174C-.577-1.3-.53-.244.181.727" transform="translate(22.572 69.769)" fill="#892233" />
                <path id="Path_180" data-name="Path 180" d="M.1.7c.822,1.422,3,2.186,3.362.867C3.724.589,2.6-1.125.946-1.253-.394-1.217-.355-.088.1.7" transform="translate(27.838 70.173)" fill="#892233" />
                <path id="Path_181" data-name="Path 181" d="M.043.667C.634,2.139,2.6,2.6,3.192,1.405A2.019,2.019,0,0,0,1.241-1.2,1.273,1.273,0,0,0,.043.667" transform="translate(33.493 70.429)" fill="#892233" />
                <path id="Path_182" data-name="Path 182" d="M.01.634a1.575,1.575,0,0,0,2.831.62A1.54,1.54,0,0,0,1.478-1.138,1.484,1.484,0,0,0,.01.634" transform="translate(39.338 70.541)" fill="#892233" />
                <path id="Path_183" data-name="Path 183" d="M0,.6c0,1.2,1.4,1.573,2.336.631.872-.885.643-2.288-.7-2.3A1.764,1.764,0,0,0,0,.6" transform="translate(45.168 70.516)" fill="#892233" />
                <path id="Path_184" data-name="Path 184" d="M.011.557c-.2.953.615,1.366,1.551.793C2.644.682,2.956-.7,2.083-.96A1.368,1.368,0,0,0,1.721-1,2.114,2.114,0,0,0,.011.557" transform="translate(50.779 70.36)" fill="#892233" />
                <path id="Path_185" data-name="Path 185" d="M.036.516c-.288.729.07,1.132.772.855C1.9.954,2.738-.515,2.085-.867a.668.668,0,0,0-.36-.059A2.507,2.507,0,0,0,.036.516" transform="translate(55.984 70.086)" fill="#892233" />
                <path id="Path_186" data-name="Path 186" d="M.254.67c1.085,1.3,2.9,2.2,2.926,1.247C3.2,1.045,1.524-.985.1-1.2-.79-1.224-.516-.244.254.67" transform="translate(18.195 69.325)" fill="#892233" />
                <path id="Path_187" data-name="Path 187" d="M.318.616C1.391,1.8,2.712,2.567,2.709,2A5.989,5.989,0,0,0-.278-1.1c-.62-.03-.176.864.6,1.719" transform="translate(14.753 68.78)" fill="#892233" />
                <path id="Path_188" data-name="Path 188" d="M.372.564c.891.95,1.911,1.711,1.919,1.423a8.634,8.634,0,0,0-2.833-3c-.411-.05.307.869.389.97.154.2.33.4.525.6" transform="translate(12.257 68.178)" fill="#892233" />
                <path id="Path_189" data-name="Path 189" d="M.411.521a10.169,10.169,0,0,0,1.5,1.391A18.931,18.931,0,0,0-.711-.923c-.009-.006-.019,0-.025-.011C-.782-.791.077.164.411.521" transform="translate(10.681 67.526)" fill="#892233" />
                <path id="Path_190" data-name="Path 190" d="M.282.586c1.156,1.1,3.043,1.886,3.041,1.085C3.32.913,1.539-.855.084-1.051-.83-1.068-.577-.307.126.43.175.478.237.544.282.586" transform="translate(23.181 73.28)" fill="#892233" />
                <path id="Path_191" data-name="Path 191" d="M.169.558c1,1.156,2.925,1.8,2.946.752A3.1,3.1,0,0,0,.474-1C-.541-1-.474-.19.169.558" transform="translate(27.545 73.734)" fill="#892233" />
                <path id="Path_192" data-name="Path 192" d="M.084.529c.768,1.215,2.591,1.564,2.781.536C3,.332,2.135-.863.8-.95-.318-.914-.295-.062.084.529" transform="translate(32.308 74.043)" fill="#892233" />
                <path id="Path_193" data-name="Path 193" d="M.026.5C.44,1.621,2.1,1.9,2.559.93A1.411,1.411,0,0,0,1.092-.9C.018-.845-.178-.043.026.5" transform="translate(37.28 74.218)" fill="#892233" />
                <path id="Path_194" data-name="Path 194" d="M0,.476A1.182,1.182,0,0,0,2.143.9,1.018,1.018,0,0,0,1.319-.854,1.3,1.3,0,0,0,0,.476" transform="translate(42.293 74.256)" fill="#892233" />
                <path id="Path_195" data-name="Path 195" d="M.007.446c-.156.786.651,1.1,1.478.568.889-.564,1.07-1.582.322-1.788A1.329,1.329,0,0,0,1.486-.8,1.712,1.712,0,0,0,.007.446" transform="translate(47.179 74.164)" fill="#892233" />
                <path id="Path_196" data-name="Path 196" d="M.035.418c-.269.59.033.892.673.682,1-.341,1.78-1.515,1.2-1.8a.656.656,0,0,0-.332-.05A2.246,2.246,0,0,0,.035.418" transform="translate(51.786 73.953)" fill="#892233" />
                <path id="Path_197" data-name="Path 197" d="M.074.387c-.305.431-.274.707.09.667C.976.951,2.28-.374,1.84-.656A.431.431,0,0,0,1.6-.694,2.794,2.794,0,0,0,.074.387" transform="translate(55.974 73.637)" fill="#892233" />
                <path id="Path_198" data-name="Path 198" d="M.367.547c1.075.92,2.64,1.727,2.615,1.221C2.957,1.209.853-.749-.338-.979c-.74-.042.022.841.111.944A6.534,6.534,0,0,0,.367.547" transform="translate(19.722 72.799)" fill="#892233" />
                <path id="Path_199" data-name="Path 199" d="M.436.507C1.448,1.331,2.66,2.03,2.668,1.795A9.731,9.731,0,0,0-.626-.906C-.844-.935-.835-.8-.6-.491a6.7,6.7,0,0,0,1.034,1" transform="translate(17.057 72.234)" fill="#892233" />
                <path id="Path_200" data-name="Path 200" d="M.486.472A10.476,10.476,0,0,0,2.339,1.751s.011-.014.014-.016A21.582,21.582,0,0,0-.816-.845a.108.108,0,0,0-.055,0A5.779,5.779,0,0,0,.486.472" transform="translate(15.189 71.615)" fill="#892233" />
                <path id="Path_201" data-name="Path 201" d="M1.052.863C.038.1-.9-.75-1.888-1.549l.033.033C-.989-.8.154.183,1.052.863" transform="translate(15.078 71.732)" fill="#892233" />
                <path id="Path_202" data-name="Path 202" d="M.282.433c1.057.762,2.749,1.33,2.669.777A4.424,4.424,0,0,0-.013-.777C-.926-.8-.308-.1-.233-.011A3.508,3.508,0,0,0,.282.433" transform="translate(28.476 76.77)" fill="#892233" />
                <path id="Path_203" data-name="Path 203" d="M.158.409C1,1.192,2.611,1.639,2.6.932,2.6.37,1.547-.639.359-.734c-.853,0-.791.6-.2,1.143" transform="translate(32.225 77.103)" fill="#892233" />
                <path id="Path_204" data-name="Path 204" d="M.066.389c.559.791,2.11,1.12,2.236.279C2.374.157,1.769-.645.693-.7-.151-.668-.291-.114.066.389" transform="translate(36.169 77.31)" fill="#892233" />
                <path id="Path_205" data-name="Path 205" d="M.01.368a1.145,1.145,0,0,0,2,.2C2.276.09,1.976-.635.993-.661.122-.6-.108-.024.01.368" transform="translate(40.17 77.394)" fill="#892233" />
                <path id="Path_206" data-name="Path 206" d="M0,.351c-.089.66.758.847,1.486.327.682-.487.665-1.2-.03-1.3A1.78,1.78,0,0,0,1.253-.63,1.335,1.335,0,0,0,0,.351" transform="translate(44.099 77.367)" fill="#892233" />
                <path id="Path_207" data-name="Path 207" d="M.034.331c-.248.447.012.679.575.515C1.49.6,2.234-.254,1.818-.529a.612.612,0,0,0-.354-.064A2.032,2.032,0,0,0,.034.331" transform="translate(47.84 77.232)" fill="#892233" />
                <path id="Path_208" data-name="Path 208" d="M.084.315C-.159.569-.271.832.03.827.718.821,2.129-.219,1.857-.51c-.062-.069-.185-.055-.26-.051A3.061,3.061,0,0,0,.084.315" transform="translate(51.298 76.999)" fill="#892233" />
                <path id="Path_209" data-name="Path 209" d="M.139.3c-.273.213-.5.45-.333.472C.254.825,2.008-.292,1.8-.508c-.031-.031-.089-.02-.154-.019A4.542,4.542,0,0,0,.139.3" transform="translate(54.385 76.673)" fill="#892233" />
                <path id="Path_210" data-name="Path 210" d="M.25.507C.8,1.581.914,1.77.948,1.815a4.127,4.127,0,0,0-.355-.78L-.047-.208c-.1-.182-.343-.621-.4-.7A12.8,12.8,0,0,0,.25.507" transform="translate(77.017 21.55)" fill="#892233" />
                <path id="Path_211" data-name="Path 211" d="M.17.467c.425.81.841,1.342.894,1.142A5.226,5.226,0,0,0-.25-.838c-.19,0,.157.8.42,1.305" transform="translate(75.822 21.1)" fill="#892233" />
                <path id="Path_212" data-name="Path 212" d="M0,.005c.472.95,1.043,1.4,1.142,1.051A1.631,1.631,0,0,0,.9.084l0-.011c.35.758.29,1.126.033,1.054C.772,1.08.395.791,0-.009A.023.023,0,0,0,0,0Z" transform="translate(73.384 20.999)" fill="#892233" />
                <path id="Path_213" data-name="Path 213" d="M.091.524c.335.936.794,1.512.931,1.168C1.211,1.2.505-.813-.01-.941c-.3.028-.095.914.1,1.465" transform="translate(77.016 26.347)" fill="#892233" />
                <path id="Path_214" data-name="Path 214" d="M.016.051c.313.883.908,1.4,1.142.986a1.458,1.458,0,0,0-.04-1.075v0l0-.006c.262.917.016,1.36-.383,1.134A2.056,2.056,0,0,1-.029-.092l0,.011h0l0,.006c.012.034.028.09.04.126" transform="translate(73.759 26.513)" fill="#892233" />
                <path id="Path_215" data-name="Path 215" d="M.092.492c.477.972,1.316,1.52,1.528.99A2.37,2.37,0,0,0,.174-.882C-.362-.856-.161-.029.092.492" transform="translate(69.366 20.238)" fill="#892233" />
                <path id="Path_216" data-name="Path 216" d="M.091.381C.5,1.235,1.12,1.8,1.226,1.42A1.619,1.619,0,0,0,.986.465l0-.006C.656-.179.271-.626,0-.684-.246-.676-.208-.27.087.375Z" transform="translate(73.281 20.594)" fill="#892233" />
                <path id="Path_217" data-name="Path 217" d="M.046.553C.431,1.712,1.375,2.259,1.7,1.519,2.043.753,1.34-.912.453-.993c-.659.048-.6.978-.406,1.546" transform="translate(68.969 25.763)" fill="#892233" />
                <path id="Path_218" data-name="Path 218" d="M.041.43h0l0,0c.329,1.025.953,1.5,1.18,1.109A1.4,1.4,0,0,0,1.184.467l0-.009C.941-.245.567-.729.229-.771-.092-.751-.16-.293.041.43" transform="translate(73.681 25.975)" fill="#892233" />
                <path id="Path_219" data-name="Path 219" d="M.194.581C.013.941-.18,1.3-.348,1.668A.248.248,0,0,0-.3,1.59L.247.561.943-.9a.667.667,0,0,0,.062-.145C.694-.523.463.039.194.581" transform="translate(77.184 57.735)" fill="#892233" />
                <path id="Path_220" data-name="Path 220" d="M.117.532c-.248.536-.419,1.05-.271,1C.174,1.405,1.313-.91,1.063-.954L1.04-.946A5.034,5.034,0,0,0,.117.532" transform="translate(76.181 58.21)" fill="#892233" />
                <path id="Path_221" data-name="Path 221" d="M.079.075c-.332.772-.251,1.089-.014.981A2.453,2.453,0,0,0,.956-.121s0-.012.006-.014C.52.8-.005,1.253-.106.964c-.04-.111-.036-.354.2-.9A.034.034,0,0,0,.081.069l0,0Z" transform="translate(73.941 59.013)" fill="#892233" />
                <path id="Path_222" data-name="Path 222" d="M.216.543a6.473,6.473,0,0,0-.6.972A13.366,13.366,0,0,0,1.348-.972a.027.027,0,0,1-.022,0A17.235,17.235,0,0,0,.216.543" transform="translate(73.882 62.902)" fill="#892233" />
                <path id="Path_223" data-name="Path 223" d="M.137.492c-.354.561-.481.933-.3.875C.285,1.22,1.618-.729,1.358-.877a.1.1,0,0,0-.056,0A4.649,4.649,0,0,0,.137.492" transform="translate(72.374 63.468)" fill="#892233" />
                <path id="Path_224" data-name="Path 224" d="M.078.114c-.3.536-.291.872.009.765A2.732,2.732,0,0,0,1.128-.2a.012.012,0,0,0,.006,0C.58.662-.06,1.087-.119.754A1.394,1.394,0,0,1,.142,0l0,0,0,.006,0,0C.119.04.1.081.078.114" transform="translate(69.667 64.381)" fill="#892233" />
                <path id="Path_225" data-name="Path 225" d="M.022.619c-.238.95.234,1.464.955,1.025C2.061.991,2.57-.911,1.712-1.1a1.13,1.13,0,0,0-.193-.011C.751-.973.183-.014.022.619" transform="translate(65.317 59.508)" fill="#892233" />
                <path id="Path_226" data-name="Path 226" d="M.04.547c-.246.746-.072,1.2.391.989.863-.389,1.635-2.338,1-2.51A.461.461,0,0,0,1.3-.98C.739-.859.228-.015.04.547" transform="translate(70.182 59.123)" fill="#892233" />
                <path id="Path_227" data-name="Path 227" d="M.079.439c-.287.653-.294,1.106-.014.983A2.643,2.643,0,0,0,.949.246l0,0,0,0c.276-.649.3-1.05.058-1.029A2.344,2.344,0,0,0,.082.433Z" transform="translate(73.957 58.62)" fill="#892233" />
                <path id="Path_228" data-name="Path 228" d="M.053.5C-.241,1.153-.081,1.55.4,1.36,1.275,1.014,2.169-.613,1.627-.864a.363.363,0,0,0-.2-.022A2.525,2.525,0,0,0,.053.5" transform="translate(65.728 64.503)" fill="#892233" />
                <path id="Path_229" data-name="Path 229" d="M.1.4h0c-.321.567-.369.939-.1.885A2.578,2.578,0,0,0,1.092.206V.193h0c.338-.581.377-.934.1-.911A2.752,2.752,0,0,0,.1.4" transform="translate(69.721 63.96)" fill="#892233" />
                <path id="Path_230" data-name="Path 230" d="M.213.429a4.223,4.223,0,0,0-.595.726l.009.009A9.386,9.386,0,0,0,1.473-.768c-.008,0-.023,0-.033,0A13.5,13.5,0,0,0,.213.429" transform="translate(69.385 68.121)" fill="#892233" />
                <path id="Path_231" data-name="Path 231" d="M.164.077C-.306.63-.353.821-.247.818A3.376,3.376,0,0,0,.905-.129l0-.006.008,0C.31.51-.235.9-.271.765-.281.709-.239.558.18.071l-.008,0,0,0Z" transform="translate(67.574 68.942)" fill="#892233" />
                <path id="Path_232" data-name="Path 232" d="M.314.413c-.434.344-.791.612-.877.681L1.662-.668c.028-.022.056-.048.084-.073l-.012,0C1.256-.366.795.03.314.413" transform="translate(65.797 71.588)" fill="#892233" />
                <path id="Path_233" data-name="Path 233" d="M.228.367a3.579,3.579,0,0,0-.637.59A.068.068,0,0,1-.393.967,8.622,8.622,0,0,0,1.648-.645a.032.032,0,0,0-.02-.014L1.6-.65A10.075,10.075,0,0,0,.228.367" transform="translate(64.62 72.172)" fill="#892233" />
                <path id="Path_234" data-name="Path 234" d="M.149.1C-.1.327-.35.6-.241.626a3.439,3.439,0,0,0,1.246-.79l0,0s0,0,0-.008C.348.388-.267.721-.242.562c.009-.055.1-.2.483-.547l0,.008L.23.026l0,0C.208.053.172.076.149.1" transform="translate(62.572 72.976)" fill="#892233" />
                <path id="Path_235" data-name="Path 235" d="M.3.289c-.36.2-.528.3-.83.459L1.359-.307c.117-.069.235-.134.349-.212H1.7C1.225-.265.767.024.3.289" transform="translate(60.35 75.318)" fill="#892233" />
                <path id="Path_236" data-name="Path 236" d="M.244.061A3.844,3.844,0,0,0-.438.5,7.216,7.216,0,0,0,.778-.108l.006,0a7.6,7.6,0,0,1-1.2.6C-.4.465-.4.441.253.052h0l0,.008Z" transform="translate(59.136 75.964)" fill="#892233" />
                <path id="Path_237" data-name="Path 237" d="M.072.474c-.335.584-.243.925.22.811C1.209,1.063,2.389-.463,1.917-.8a.358.358,0,0,0-.26-.047A2.87,2.87,0,0,0,.072.474" transform="translate(60.61 69.712)" fill="#892233" />
                <path id="Path_238" data-name="Path 238" d="M.106.43C-.242.9-.288,1.218,0,1.163c.668-.128,2.04-1.7,1.666-1.92a.317.317,0,0,0-.145-.011A3.473,3.473,0,0,0,.106.43" transform="translate(64.506 69.245)" fill="#892233" />
                <path id="Path_239" data-name="Path 239" d="M.164.347c-.383.445-.55.748-.41.737A3.367,3.367,0,0,0,.9.14l0,0c.529-.612.5-.768.414-.76a.055.055,0,0,0-.036,0A3.988,3.988,0,0,0,.164.347" transform="translate(67.597 68.648)" fill="#892233" />
                <path id="Path_240" data-name="Path 240" d="M.115.355C-.223.718-.3.964-.092.947.472.931,1.948-.349,1.7-.607,1.662-.646,1.6-.639,1.537-.631A3.633,3.633,0,0,0,.115.355" transform="translate(59.614 73.225)" fill="#892233" />
                <path id="Path_241" data-name="Path 241" d="M.176.3h0C-.205.629-.386.868-.292.887A3.328,3.328,0,0,0,.95.1l0,0c.638-.568.492-.626.475-.628s-.045.006-.067,0A4.9,4.9,0,0,0,.176.3" transform="translate(62.648 72.691)" fill="#892233" />
                <path id="Path_242" data-name="Path 242" d="M.182.274C-.173.506-.343.654-.326.7A4.718,4.718,0,0,0,1.7-.474c0-.025-.044-.017-.092-.014A7.665,7.665,0,0,0,.182.274" transform="translate(57.012 76.275)" fill="#892233" />
                <path id="Path_243" data-name="Path 243" d="M.244.231A5.649,5.649,0,0,0-.438.668a7.261,7.261,0,0,0,1.21-.6l.008,0c.587-.347.682-.447.693-.477-.008,0-.03,0-.039.006A9.308,9.308,0,0,0,.246.231Z" transform="translate(59.161 75.78)" fill="#892233" />
                <path id="Path_244" data-name="Path 244" d="M.384.405c1.029.649,2.563,1.254,2.489.9A6.98,6.98,0,0,0-.4-.724C-.83-.755-.7-.509-.52-.305a4.391,4.391,0,0,0,.9.71" transform="translate(25.28 76.381)" fill="#892233" />
                <path id="Path_245" data-name="Path 245" d="M.467.385a7.336,7.336,0,0,0,2.244.984A11.286,11.286,0,0,0-.69-.687c-.072-.006-.246-.031-.073.181A5.729,5.729,0,0,0,.467.385" transform="translate(22.705 75.914)" fill="#892233" />
                <path id="Path_246" data-name="Path 246" d="M.522.362a12.12,12.12,0,0,0,1.993.981A28.768,28.768,0,0,0-.874-.644a.108.108,0,0,0-.062,0A6.293,6.293,0,0,0,.522.362" transform="translate(20.759 75.383)" fill="#892233" />
                <path id="Path_247" data-name="Path 247" d="M1.168.683C.076.061-.976-.614-2.076-1.219c-.008,0-.017-.008-.02-.006a3.28,3.28,0,0,0,.475.315C-.786-.429.315.239,1.168.683" transform="translate(20.549 75.42)" fill="#892233" />
                <path id="Path_248" data-name="Path 248" d="M.406.294a6.739,6.739,0,0,0,2.35.653A8.313,8.313,0,0,0-.461-.523c-.111-.009-.4-.023-.2.195A3.632,3.632,0,0,0,.406.294" transform="translate(30.177 78.696)" fill="#892233" />
                <path id="Path_249" data-name="Path 249" d="M.251.269C1.081.7,2.42,1.041,2.3.727A4.269,4.269,0,0,0-.089-.482c-.533-.008-.349.241-.243.355a2.065,2.065,0,0,0,.582.4" transform="translate(33.004 79.005)" fill="#892233" />
                <path id="Path_250" data-name="Path 250" d="M.124.253C.714.673,1.976.977,1.89.5A1.953,1.953,0,0,0,.252-.454C-.4-.445-.265-.1-.03.13A1.668,1.668,0,0,0,.124.253" transform="translate(36.006 79.216)" fill="#892233" />
                <path id="Path_251" data-name="Path 251" d="M.035.238C.393.713,1.487.862,1.6.335,1.662.04,1.295-.4.569-.427c-.6.033-.762.368-.534.665" transform="translate(39.064 79.322)" fill="#892233" />
                <path id="Path_252" data-name="Path 252" d="M0,.226c.025.486.945.512,1.367.04.273-.3.111-.667-.5-.673C.255-.362-.008,0,0,.226" transform="translate(42.073 79.34)" fill="#892233" />
                <path id="Path_253" data-name="Path 253" d="M.024.215C-.154.492.035.623.458.528c.709-.154,1.3-.679.962-.863a.679.679,0,0,0-.288-.05,1.666,1.666,0,0,0-1.109.6" transform="translate(44.941 79.258)" fill="#892233" />
                <path id="Path_254" data-name="Path 254" d="M.081.207C-.009.277-.283.5-.061.52.371.557,1.782-.1,1.556-.335c-.042-.042-.146-.036-.2-.034A3.222,3.222,0,0,0,.081.207" transform="translate(47.611 79.106)" fill="#892233" />
                <path id="Path_255" data-name="Path 255" d="M.141.2c-.4.216-.394.266-.392.28a3.711,3.711,0,0,0,1.866-.8c0-.034-.083-.025-.128-.023A5.92,5.92,0,0,0,.141.2" transform="translate(50.036 78.876)" fill="#892233" />
                <path id="Path_256" data-name="Path 256" d="M.189.187C-.287.4-.321.433-.34.453c0,0,.006,0,.006,0A6.943,6.943,0,0,0,1.6-.322c-.02-.016-.02-.016-.07-.008A9.9,9.9,0,0,0,.189.187" transform="translate(52.16 78.582)" fill="#892233" />
                <path id="Path_257" data-name="Path 257" d="M.254.162h0A6.487,6.487,0,0,0-.455.453C-.372.442.218.238.735.034l0,0A3.45,3.45,0,0,0,1.454-.29a.065.065,0,0,0-.034,0c-.081.025-.188.058-1.166.449" transform="translate(53.983 78.208)" fill="#892233" />
                <path id="Path_258" data-name="Path 258" d="M.508.284a12.069,12.069,0,0,0,2.255.7c.006,0,0-.016.009-.017A23.82,23.82,0,0,0-.775-.5c-.084-.011-.1-.011-.135.009-.034.1.572.438,1.419.777" transform="translate(27.873 78.342)" fill="#892233" />
                <path id="Path_259" data-name="Path 259" d="M.582.279A21.744,21.744,0,0,0,2.7,1C2.654.937-.685-.411-.981-.492-1-.5-1.026-.5-1.045-.5A7.363,7.363,0,0,0,.582.279" transform="translate(26.033 77.933)" fill="#892233" />
                <path id="Path_260" data-name="Path 260" d="M1.144.469C.07.08-.963-.406-2.023-.833c-.011,0-.019,0-.03-.009a2.21,2.21,0,0,0,.313.154C-.879-.341.267.152,1.144.469" transform="translate(25.604 77.83)" fill="#892233" />
                <path id="Path_261" data-name="Path 261" d="M.4.132A14.5,14.5,0,0,0,2,.339l.207.022c.008,0,.026,0,.033,0C1.464.216,1.464.216,1,.1A11.582,11.582,0,0,0-.558-.231c-.111-.008-.131-.014-.16.011,0,.081.48.229,1.118.352" transform="translate(35.02 80.219)" fill="#892233" />
                <path id="Path_262" data-name="Path 262" d="M.235.115A8.392,8.392,0,0,0,1.681.28,8.573,8.573,0,0,0-.2-.206c-.07,0-.293-.006-.2.087A1.613,1.613,0,0,0,.235.115" transform="translate(36.991 80.385)" fill="#892233" />
                <path id="Path_263" data-name="Path 263" d="M.1.1C.4.2,1.265.291,1.211.177A1.715,1.715,0,0,0,.138-.187c-.366.006-.339.12-.271.174A.629.629,0,0,0,.1.1" transform="translate(38.992 80.479)" fill="#892233" />
                <path id="Path_264" data-name="Path 264" d="M.011.1C.156.238.981.234,1,.044,1.006-.03.882-.174.466-.176.051-.159-.089,0,.011.1" transform="translate(40.944 80.506)" fill="#892233" />
                <path id="Path_265" data-name="Path 265" d="M.014.094c-.089.084,0,.087.033.093C.413.207,1.218.024,1.062-.121A.481.481,0,0,0,.8-.168,1.527,1.527,0,0,0,.014.094" transform="translate(42.762 80.47)" fill="#892233" />
                <path id="Path_266" data-name="Path 266" d="M.087.1A.863.863,0,0,0-.157.174,5.3,5.3,0,0,0,.768.046c.336-.076.511-.156.506-.2s-.1-.023-.143-.022A5.381,5.381,0,0,0,.087.1" transform="translate(44.419 80.385)" fill="#892233" />
                <path id="Path_267" data-name="Path 267" d="M.163.1C.014.132-.142.158-.293.188a8.406,8.406,0,0,0,1.752-.35V-.169a.159.159,0,0,0-.076,0C.973-.113.573.009.163.1" transform="translate(45.926 80.244)" fill="#892233" />
                <path id="Path_268" data-name="Path 268" d="M.14.1C.009.128-.125.145-.251.173L-.128.146l.609-.1a8.038,8.038,0,0,0,1-.21.125.125,0,0,0-.044,0C1.006-.1.573.017.14.1" transform="translate(47.361 80.064)" fill="#892233" />
                <path id="Path_269" data-name="Path 269" d="M.192,0A.155.155,0,0,1,.167,0C0,.042-.173.081-.344.118Z" transform="translate(49.84 79.671)" fill="#892233" />
                <path id="Path_270" data-name="Path 270" d="M.517.136l.033,0C1.04.22,1.689.318,2.183.388,1.16.238.176-.087-.835-.238c-.064-.009-.075,0-.092,0A6.662,6.662,0,0,0,.517.136" transform="translate(33.296 80.01)" fill="#892233" />
                <path id="Path_271" data-name="Path 271" d="M.645.149C.058.024-.523-.136-1.11-.262a.242.242,0,0,1-.048,0,1.523,1.523,0,0,0,.263.083c.463.1,1.075.24,1.54.333" transform="translate(31.925 79.763)" fill="#892233" />
                </g>
                <g id="Group_1" data-name="Group 1" transform="translate(927.346 547.279)">
                <path id="Path_273" data-name="Path 273" d="M3.243,1.437,6.525,8.754H-.066Zm.173-4.015L-5.82,17.156h2.014L-.7,10.263H7.213l3.108,6.893h3.194L4.366-2.578Z" transform="translate(153.903 3.063)" fill="#892233" />
                <path id="Path_274" data-name="Path 274" d="M4.633,0-5.438,9.568V0H-8.314V19.734h2.876V10.222l9.725,9.512h4L-2.762,9.028,7.281,0Z" transform="translate(176.509 0.485)" fill="#892233" />
                <path id="Path_275" data-name="Path 275" d="M0,0V19.734H2.964V10.507l8.891.058V8.94L2.964,9V1.509l9.266.171V0Z" transform="translate(186.148 0.485)" fill="#892233" />
                <path id="Path_276" data-name="Path 276" d="M159.709-10.942h2.907V-30.676h-2.907Z" transform="translate(41.659 31.16)" fill="#892233" />
                <path id="Path_277" data-name="Path 277" d="M4.088,1.365a7.292,7.292,0,0,0-5.5-2.306H-4.457V15.775h3.049a7.387,7.387,0,0,0,5.5-2.306A8.306,8.306,0,0,0,6.332,7.431,8.328,8.328,0,0,0,4.088,1.365m2.389,13.1A9.862,9.862,0,0,1-.688,17.284H-7.335V-2.45H-.688A9.768,9.768,0,0,1,6.477.4,9.421,9.421,0,0,1,9.383,7.431a9.515,9.515,0,0,1-2.906,7.034" transform="translate(7.335 2.707)" fill="#892233" />
                <path id="Path_278" data-name="Path 278" d="M1.04,5H-1.866V-8.978h.61a2.283,2.283,0,0,1,2.3,2.271Z" transform="translate(21.288 14.988)" fill="#892233" />
                <path id="Path_280" data-name="Path 280" d="M42.874-27.919H45.78v-2.9H42.874Z" transform="translate(-23.453 31.079)" fill="#892233" />
                <path id="Path_281" data-name="Path 281" d="M1.03.54V9.026H4.31A4.1,4.1,0,0,0,7.275,7.887,4.04,4.04,0,0,0,8.511,4.782,4.034,4.034,0,0,0,4.31.54Zm0,9.967v8.258H-1.848V-.969H5.289A6.285,6.285,0,0,1,9.777.682a5.319,5.319,0,0,1,1.755,4.1,5.318,5.318,0,0,1-1.755,4.1,6.382,6.382,0,0,1-4.488,1.623Z" transform="translate(27.854 1.226)" fill="#892233" />
                <path id="Path_282" data-name="Path 282" d="M4.376,7.062H-7.852V-12.672h2.876V5.553l7.654-.14a1.658,1.658,0,0,1,1.7,1.649" transform="translate(49.105 12.929)" fill="#892233" />
                <path id="Path_283" data-name="Path 283" d="M6.219,3.617A9.164,9.164,0,0,0,4.119-2.5,6.328,6.328,0,0,0-.946-5.01,6.419,6.419,0,0,0-6.038-2.5,9.29,9.29,0,0,0-8.109,3.617,9.291,9.291,0,0,0-6.038,9.741,6.476,6.476,0,0,0-.946,12.217,6.383,6.383,0,0,0,4.119,9.741a9.166,9.166,0,0,0,2.1-6.123m.058,7.148A9.842,9.842,0,0,1-.946,13.7,9.908,9.908,0,0,1-8.2,10.765,9.685,9.685,0,0,1-11.16,3.617,9.635,9.635,0,0,1-8.2-3.529,9.933,9.933,0,0,1-.946-6.491,9.867,9.867,0,0,1,6.277-3.529,9.661,9.661,0,0,1,9.269,3.617a9.711,9.711,0,0,1-2.992,7.148" transform="translate(62.788 6.52)" fill="#892233" />
                <path id="Path_285" data-name="Path 285" d="M3.243,1.437,6.525,8.756H-.064Zm.173-4.015L-5.82,17.156H-3.8L-.7,10.265H7.215l3.108,6.891h3.192L4.366-2.578Z" transform="translate(102.268 2.835)" fill="#892233" />
                <path id="Path_286" data-name="Path 286" d="M4.355,6.634A7.741,7.741,0,0,1-.709,8.314a7.461,7.461,0,0,1-4.8-1.537,5.991,5.991,0,0,1-2.3-3.731l2.244-.6A4.277,4.277,0,0,0-4.017,5.5,4.963,4.963,0,0,0-.651,6.805,4.738,4.738,0,0,0,2.543,5.7,3.552,3.552,0,0,0,3.837,2.876c0-2.023-1.38-3.048-4.948-3.845-4.23-1-5.929-2.619-5.929-5.354a4.984,4.984,0,0,1,1.9-3.987A7.2,7.2,0,0,1-.421-11.9a7.074,7.074,0,0,1,4.374,1.338A5.589,5.589,0,0,1,6.168-7.377l-2.244.542A3.89,3.89,0,0,0,2.34-9.283,4.5,4.5,0,0,0-.508-10.365,4.18,4.18,0,0,0-3.327-9.4a3.017,3.017,0,0,0-1.151,2.42c0,1.708,1.236,2.506,4.574,3.3C4.671-2.563,6.4-.827,6.4,2.334a5.389,5.389,0,0,1-2.043,4.3" transform="translate(123.454 11.904)" fill="#892233" />
                <path id="Path_287" data-name="Path 287" d="M1.04,4.9H-1.866V-8.784A2.891,2.891,0,0,1,1.04-5.908Z" transform="translate(134.072 15.096)" fill="#892233" />
                <path id="Path_289" data-name="Path 289" d="M7.24,0H4.857L-2.528,15.058-9.912,0h-3.079V19.734h2.5V3.833l7.483,15.9h.962l7.483-15.9v15.9h2.5V0Z" transform="translate(86.869 0.257)" fill="#892233" />
                <path id="Path_290" data-name="Path 290" d="M6.8,0-.137,15.52-5.393,4.18A7.246,7.246,0,0,0-11.976,0H-12.2V2.876h.221A4.4,4.4,0,0,1-7.992,5.391l6.734,14.343h.835L8.813,0Z" transform="translate(144.403 0.484)" fill="#892233" />
                </g>
            </g>
            </svg>
    );
};

export default Logo;
