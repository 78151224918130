import { useRoutes } from 'react-router-dom';

// routes
import DashboardRoutes from './DashboardRoutes';
import TurkeyinfoRoutes from './TurkeyInfoRoutes';
import DiplomasiVakfiRoutes from './DiplomasiVakfiRoutes';
import EarnPortalRoutes from './EarnPortalRoutes';

import config from 'config';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([DashboardRoutes, DiplomasiVakfiRoutes, TurkeyinfoRoutes, EarnPortalRoutes], config.basename);
}
